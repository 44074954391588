<template>
  <div id="print">
    <p style="text-align: center; font-weight: 600; font-size: 18px">{{ orderData.customer }} {{orderData.adjust_orders?"调整单":"结算单"}}</p>
    <p style="width: 300px; float: left;">流水号：{{ orderData.record_no }}</p>
    <p v-if="orderData.currency === 'CNY'" style="width: 200px; float: right;">币种：{{ orderData.currency }}</p>
    <p v-else style="width: 200px; float: right;">汇率：{{ orderData.balanceExchangeRate }} 币种：{{ orderData.currency }}</p>
    <div>
      <table>
        <tbody>
          <tr v-if="orderData.currency === 'CNY'">
            <th rowspan="2">接单日期</th>
            <th rowspan="2">报价编号</th>
            <th rowspan="2">业务类型</th>
            <th colspan="2">应收费用</th>
            <th colspan="3">应付费用</th>
            <th rowspan="2">利润</th>
          </tr>
          <tr v-else>
            <th rowspan="2">接单日期</th>
            <th rowspan="2">报价编号</th>
            <th rowspan="2">业务类型</th>
            <th colspan="3">应收费用</th>
            <th colspan="4">应付费用</th>
            <th rowspan="2">利润</th>
          </tr>
          <tr v-if="orderData.currency === 'CNY'">
            <th class="w100">费用名称</th>
            <th class="w100">金额</th>
            <th class="w100">费用名称</th>
            <th class="w100">金额</th>
            <th class="w200">付款对象</th>
          </tr>
          <tr v-else>
            <th class="w100">费用名称</th>
            <th class="w100">原币种</th>
            <th class="w100">转换人民币</th>
            <th class="w100">费用名称</th>
            <th class="w100">原币种</th>
            <th class="w100">转换人民币</th>
            <th class="w200">付款对象</th>
          </tr>
          <tr v-for="item in orderList" :key="item.biz_num">
            <td>{{ moment(item.record_item).format('YYYY/MM/DD') }}</td>
            <td>{{ item.quota_num }}</td>
            <td>{{ item.business_type }}</td>
            <td :colspan="orderData.currency === 'CNY' ? 2 : 3">
              <table class="table2" style="min-width:200px">
                <tbody v-if="item.charge_fees">
                  <tr v-for="fees in item.charge_fees" :key="fees.id">
                    <td class="w100">{{ fees.fee_name }}</td>
                    <td class="w100" style="text-align: right;">{{ moneyFormatter(fees.amount) }}</td>
                    <td v-if="orderData.currency !== 'CNY'" class="w100" style="text-align: right;">{{ moneyFormatter(fees.exchange_amount) }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td :colspan="orderData.currency === 'CNY' ? 3 : 4">
              <table class="table2" style="min-width:400px">
                <tbody v-if="item.pay_fees">
                  <tr v-for="fees in item.pay_fees" :key="fees.id">
                    <td class="w100">{{ fees.fee_name }}</td>
                    <td class="w100" style="text-align: right;">{{ moneyFormatter(fees.amount) }}</td>
                    <td v-if="orderData.currency !== 'CNY'" class="w100" style="text-align: right;">{{ moneyFormatter(fees.exchange_amount) }}</td>
                    <td class="w200">{{ fees.pay_target }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td class="w100" style="text-align: right;">{{ moneyFormatter(item.profit) }}</td>
          </tr>
          <tr>
            <td colspan="3">合计</td>
            <td :colspan="orderData.currency === 'CNY' ? 2 : 3">
              <table class="table2" style="min-width:200px">
                <tbody>
                  <tr>
                    <td style="text-align: right;">{{ moneyFormatter(charge) }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td :colspan="orderData.currency === 'CNY' ? 3 : 4">
              <table class="table2" style="min-width:400px">
                <tbody>
                  <tr>
                    <td style="text-align: right;">{{ moneyFormatter(pay) }}</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td class="w100" style="text-align: right;">{{ moneyFormatter(profit) }}</td>
          </tr>
        </tbody>
      </table>
      <p>开票备注：{{ orderData.apply_remark }}</p>
    </div>
    <p id="footer">
      <span>开票人：{{ userInfo.name }}</span>
      <span>业务员：{{ orderData.applicant_name }} </span>
      <span>部门经理：徐晨</span>
      <span>{{ date }}</span>
    </p>
    <div v-for="adjust in orderData.adjust_orders" :key="adjust.id">
      <p style="text-align: center; font-weight: 800;">
        <a-divider orientation="center" style="font-size: 18px">
          {{ adjust.quota_num }} 调整单{{ orderData.fms_sap_num ? '（SAP编号:' + orderData.fms_sap_num + '）' : '' }}
        </a-divider>
      </p>
      <div v-for="detail in adjust.adjust_details" :key="detail.id">
        <p style="text-align: left; font-weight: 600">
          <a-divider orientation="left">
            {{ detail.adjust_type }}
          </a-divider>
        </p>
        <table v-if="detail.adjust_type === '删除费用'|| detail.adjust_type === '新增费用'">
          <tbody>
            <tr>
              <th>费用类型</th>
              <th>费用名称</th>
              <th>币种</th>
              <th>金额</th>
              <th>付款对象</th>
              <th>客户名称</th>
            </tr>
            <tr v-for="fee in detail.delete_fees" :key="fee.id">
              <td class="w100">{{ fee.fee_type?(fee.fee_type===1?'应收' : '应付'):'' }}</td>
              <td class="w100">{{ fee.fee_name }}</td>
              <td class="w100">{{ fee.currency_name }}</td>
              <td class="w100">{{ fee.money }}</td>
              <td class="w100">{{ fee.pay_target_name }}</td>
              <td class="w100">{{ fee.fee_type&&fee.fee_type===1?orderData.customer : '' }}</td>
            </tr>
            <tr v-for="fee in detail.add_fees" :key="fee.id">
              <td class="w100">{{ fee.fee_type?(fee.fee_type===1?'应收' : '应付'):'' }}</td>
              <td class="w100">{{ fee.fee_name }}</td>
              <td class="w100">{{ fee.currency_name }}</td>
              <td class="w100">{{ fee.money }}</td>
              <td class="w100">{{ fee.pay_target_name }}</td>
              <td class="w100">{{ fee.fee_type&&fee.fee_type===1?orderData.customer : '' }}</td>
            </tr>
          </tbody>
        </table>
        <table v-if="detail.adjust_type === '修改客户'">
          <tbody>
            <tr>
              <th>原客户</th>
              <th>新客户</th>
            </tr>
            <tr>
              <td class="w100">{{ detail.original_customer_name }}</td>
              <td class="w100">{{ detail.new_customer_name }}</td>
            </tr>
          </tbody>
        </table>
        <table v-if="detail.adjust_type === '重新开票'">
          <tbody>
            <tr>
              <th>原票号</th>
              <th>新票号</th>
            </tr>
            <tr>
              <td class="w100">{{ detail.original_invoices }}</td>
              <td class="w100">{{ detail.new_invoices }}</td>
            </tr>
          </tbody>
        </table>
        <div v-if="detail.adjust_type === '作废发票'">
          <span>作废票号：{{ detail.original_invoices }}</span>
        </div>
      </div>
      <div v-if="adjust.fms_adjust_info">
        <!-- <p style="text-align: left; font-weight: 600">
          <a-divider orientation="left"> 审核信息 </a-divider>
        </p> -->
        <a-row :gutter="24">
          <a-col :span="24">
            调整原因:{{ adjust.fms_adjust_info.reason_description}}
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="4" v-for="flow in adjust.fms_adjust_info.adjust_flow_list" :key="flow.id">
            {{flow.flow_status_name}}：{{flow.flow_user_name}}-{{ moment(flow.created_at).format('YYYY/MM/DD') }}
          </a-col>
        </a-row>
      </div>
    </div>
    <div v-if="orderData.adjust_orders&&orderData.adjust_orders.length > 0">
      <p style="text-align: center; font-weight: 800;">
        <a-divider orientation="center" style="font-size: 18px">
          原结算单
          <div v-for="detail in orderData.adjust_orders[0].adjust_details" :key="detail.id">
            <span v-if="detail.adjust_type === '修改客户'">{{ detail.original_customer_name }}</span>
          </div>
        </a-divider>
      </p>
      <p style="width: 300px; float: left;">流水号：{{ orderData.record_no }}</p>
      <p v-if="orderData.currency === 'CNY'" style="width: 200px; float: right;">币种：{{ orderData.currency }}</p>
      <p v-else style="width: 200px; float: right;">汇率：{{ orderData.balanceExchangeRate }} 币种：{{ orderData.currency }}</p>
      <div>
        <table>
          <tbody>
            <tr v-if="orderData.currency === 'CNY'">
              <th rowspan="2">接单日期</th>
              <th rowspan="2">报价编号</th>
              <th rowspan="2">业务类型</th>
              <th colspan="2">应收费用</th>
              <th colspan="3">应付费用</th>
              <th rowspan="2">利润</th>
            </tr>
            <tr v-else>
              <th rowspan="2">接单日期</th>
              <th rowspan="2">报价编号</th>
              <th rowspan="2">业务类型</th>
              <th colspan="3">应收费用</th>
              <th colspan="4">应付费用</th>
              <th rowspan="2">利润</th>
            </tr>
            <tr v-if="orderData.currency === 'CNY'">
              <th class="w100">费用名称</th>
              <th class="w100">金额</th>
              <th class="w100">费用名称</th>
              <th class="w100">金额</th>
              <th class="w200">付款对象</th>
            </tr>
            <tr v-else>
              <th class="w100">费用名称</th>
              <th class="w100">原币种</th>
              <th class="w100">转换人民币</th>
              <th class="w100">费用名称</th>
              <th class="w100">原币种</th>
              <th class="w100">转换人民币</th>
              <th class="w200">付款对象</th>
            </tr>
            <tr v-for="item in orderList" :key="item.biz_num">
              <td>{{ moment(item.record_item).format('YYYY/MM/DD') }}</td>
              <td>{{ item.quota_num }}</td>
              <td>{{ item.business_type }}</td>
              <td :colspan="orderData.currency === 'CNY' ? 2 : 3">
                <table class="table2" style="min-width:200px">
                  <tbody v-if="item.original_charge_fees">
                    <tr v-for="fees in item.original_charge_fees" :key="fees.id">
                      <td class="w100">{{ fees.fee_name }}</td>
                      <td class="w100" style="text-align: right;">{{ moneyFormatter(fees.amount) }}</td>
                      <td v-if="orderData.currency !== 'CNY'" class="w100" style="text-align: right;">{{ moneyFormatter(fees.exchange_amount) }}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td :colspan="orderData.currency === 'CNY' ? 3 : 4">
                <table class="table2" style="min-width:400px">
                  <tbody v-if="item.original_pay_fees">
                    <tr v-for="fees in item.original_pay_fees" :key="fees.id">
                      <td class="w100">{{ fees.fee_name }}</td>
                      <td class="w100" style="text-align: right;">{{ moneyFormatter(fees.amount) }}</td>
                      <td v-if="orderData.currency !== 'CNY'" class="w100" style="text-align: right;">{{ moneyFormatter(fees.exchange_amount) }}</td>
                      <td class="w200">{{ fees.pay_target }}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td class="w100" style="text-align: right;">{{ moneyFormatter(item.profit) }}</td>
            </tr>
            <tr>
              <td colspan="3">合计</td>
              <td :colspan="orderData.currency === 'CNY' ? 2 : 3">
                <table class="table2" style="min-width:200px">
                  <tbody>
                    <tr>
                      <td style="text-align: right;">{{ moneyFormatter(original_charge) }}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td :colspan="orderData.currency === 'CNY' ? 3 : 4">
                <table class="table2" style="min-width:400px">
                  <tbody>
                    <tr>
                      <td style="text-align: right;">{{ moneyFormatter(original_pay) }}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td class="w100" style="text-align: right;">{{ moneyFormatter(original_profit) }}</td>
            </tr>
          </tbody>
        </table>
        <p>开票备注：{{ orderData.apply_remark }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'FmsItmsBill',
  components: {},
  props: {
    currentData: {
      type: Object,
      default: null
    },
    balanceData: {
      type: Array,
      default: null
    }
  },
  created() {
    this.initData()
  },
  watch: {
    currentData(val, oldVal) {
      this.orderData = val
    },
    balanceData: {
      handler(val, oldVal) {
        this.initData()
      },
      deep: true
    }
  },
  methods: {
    moment,
    initData() {
      this.charge = this.currentData.charge_total
      this.pay = this.currentData.pay_total
      this.profit = this.currentData.profit
      this.original_charge = this.currentData.original_charge_total
      this.original_pay = this.currentData.original_pay_total
      this.original_profit = this.currentData.original_profit
      this.orderData = this.currentData
      this.orderList = this.balanceData
    },
    moneyFormatter(money) {
      if (typeof money !== 'number') {
        return ''
      }
      return (money.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
    }
  },
  data() {
    return {
      orderData: {},
      orderList: [],
      userInfo: this.$store.getters.userInfo,
      date: moment().year() + '-' + (moment().month() + 1) + '-' + moment().date(),
      charge: 0,
      pay: 0,
      profit: 0,
      original_charge: 0,
      original_pay: 0,
      original_profit: 0
    }
  }
}
</script>

<style scoped>
@page{
  size: auto;   /* auto is the initial value */
  margin: 0;  /* this affects the margin in the printer settings */
}

body{
  margin: 0; /* margin you want for the content */
}

@media print {
  html, body {
    height: inherit;
  }
}
#print { margin: 0 auto; padding: 20px}
#print p, #print table { width: 100% }
table { border-collapse: collapse; text-align: center; height: 100%; }
td, th { border: 1px solid #000; padding: 0px }
.w50 { width:50px }
.w100 { width:100px }
.w200 { width:200px }
.table2 { font-size: 12px; }
.table2 tr td:nth-child(1) { border-left: 0; }
.table2 tr td:nth-last-child(1) { border-right: 0; }
.table2 tr:nth-child(1) td{ border-top: 0; }
.table2 tr:nth-last-child(1) td{ border-bottom: 0; }
#footer span { display: inline-block; width: 20% }
#footer span:last-child { width: 30% }
</style>
