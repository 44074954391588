<template>
  <div id="print"><p style="text-align: center; font-weight: 600">总经办调整单</p>
    <p style="float: left; width: 500px;">客户名称：{{ currentData.audit_record.customer_name }}
    </p>
    <div>
      <table>
        <tbody>
          <tr>
            <th>费用名称</th>
            <th>房屋租金</th>
            <th>水费</th>
            <th>电费</th>
            <th>其他费用</th>
            <th>合计</th>
          </tr>
          <tr>
            <td>金额</td>
            <td>{{ moneyFormatter(currentData.rentTotalFee) }}</td>
            <td>{{ moneyFormatter(currentData.waterTotalFee) }}</td>
            <td>{{ moneyFormatter(currentData.electricTotalFee) }}</td>
            <td>{{ moneyFormatter(currentData.otherTotalFee) }}</td>
            <td>{{ moneyFormatter(currentData.audit_record.sum_amount) }}</td>
          </tr>
          <tr>
            <td>备注</td>
            <td colspan="5">{{ currentData.audit_record.submit_remark || currentData.audit_record.apply_remark }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <p id="footer"><span>执行人：{{ userInfo.name }}</span><span>申请人：{{ currentData.audit_record.applicant_name }}</span><span>部门经理：陈瑞雯</span><span>申请时间：{{ date }}</span></p>
    <div>
      <p style="text-align: center; font-weight: 800;">
        <a-divider orientation="center" style="font-size: 18px">
          {{ orderData.mms_adjust_order.serial_num }} 调整单{{ orderData.fms_sap_num ? '（SAP编号:' + orderData.fms_sap_num + '）' : '' }}
        </a-divider>
      </p>
      <div v-for="detail in orderData.mms_adjust_order.adjust_details" :key="detail.id">
        <p style="text-align: left; font-weight: 600">
          <a-divider orientation="left">
            {{ detail.adjust_type }}
          </a-divider>
        </p>
        <div v-if="detail.adjust_type === '删除费用'|| detail.adjust_type === '新增费用'">
          <div v-for="fee in detail.delete_fees" :key="fee.id">
            费用名称:<span>{{ fee.fee_name }}</span>;
            金额:<span>{{ fee.charge }}</span>
          </div>
          <div v-for="fee in detail.add_fees" :key="fee.id">
            费用名称:<span>{{ fee.fee_name }}</span>;
            金额:<span>{{ fee.charge }}</span>
          </div>
        </div>
        <div v-if="detail.adjust_type === '修改客户'">
          <span>由 <span style="font-weight: bold">{{ detail.original_customer_name}}</span> 改为 <span style="font-weight: bold">{{ detail.new_customer_name}}</span></span>
        </div>
        <div v-if="detail.adjust_type === '重新开票'">
          <span>由 <span style="font-weight: bold">{{ detail.original_invoices}}</span> 红冲重开 <span style="font-weight: bold">{{ detail.new_invoices}}</span></span>
        </div>
        <div v-if="detail.adjust_type === '作废发票'">
          <span>作废票号：{{ detail.original_invoices }}</span>
        </div>
      </div>
      <div v-if="orderData.adjust_flow_list">
        <p style="text-align: left; font-weight: 600">
          <a-divider orientation="left"> 审核信息 </a-divider>
        </p>
        <a-row :gutter="24">
          <a-col :span="24">
            调整原因:{{ orderData.reason_description}}
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="6" v-for="flow in orderData.adjust_flow_list	" :key="flow.id">
            {{flow.flow_status_name}}：{{flow.flow_user_name}}-{{ moment(flow.created_at).format('YYYY/MM/DD') }}
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
    
</template>

<script>

import moment from 'moment'

export default {
  name: 'MmsInvoiceNoticeBill',
  components: {},
  props: {
    currentData: {
      type: Object,
      default: null
    }
  },
  created() {
    this.initData()
  },
  watch: {
    currentData(val, oldVal) {
      this.orderData = val
    }
  },
  data() {
    return {
      orderData: {},
      orderList: [],
      userInfo: this.$store.getters.userInfo,
      date: moment().year() + '-' + (moment().month() + 1) + '-' + moment().date(),
      rentFee: 0,
      waterFee: 0,
      electricFee: 0,
      otherFee: 0
    }
  },
  methods: {
    moment,
    initData() {
      this.orderData = this.currentData
      // const balanceData = this.orderData.mms_adjust_order.balance
      // if (balanceData) {
      //   let rentTotalFee = 0; // 租金
      //   let waterTotalFee = 0; // 水费
      //   let electricTotalFee = 0; // 电费
      //   let otherTotalFee = 0; // 其他费用
      //   balanceData.fee_infos && balanceData.fee_infos.forEach(fee => {
      //       if (fee.fee_name === '租金') {
      //         rentTotalFee += fee.charge;
      //       } else if (fee.fee_name === '水费') {
      //         waterTotalFee += fee.charge;
      //       } else if (fee.fee_name === '电费') {
      //         electricTotalFee += fee.charge;
      //       } else {
      //         otherTotalFee += fee.charge;
      //       }
      //   });
      //   this.rentFee = rentTotalFee
      //   this.waterFee = waterTotalFee
      //   this.electricFee = electricTotalFee
      //   this.otherFee = otherTotalFee
      // }
    },
    moneyFormatter(money) {
      if (typeof money !== 'number') {
        return ''
      }
      return (money.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
    }
  }
}
</script>

<style scoped>
@page{
  size:  auto;   /* auto is the initial value */
  margin: 3mm;  /* this affects the margin in the printer settings */
}

html{
  background-color: #FFFFFF;
  margin: 0px;  /* this affects the margin on the html before sending to printer */
}

body{
  border: solid 1px blue ;
  width: 80%; margin: 100px auto;
}
@media print {
  html, body {
    height: inherit;
  }
}
.gutter-example >>> .ant-row > div {
  background: transparent;
  border: 0;
}
#print { width: 90%; margin: 0 auto; padding-top: 50px }
#print p, #print table { width: 100% }
table { border-collapse: collapse; text-align: center; height: 300px }
table td, th { border: 1px solid #000 }
#footer span { display: inline-block; width: 20% }
</style>
