<template>
  <a-card class="card" :bordered="false">
    <a-form-model :model="adjustQuery" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-row>
        <a-col v-bind="GLOBAL.queryColSpan" v-if="!currentUserInfo.department || currentUserInfo.department === 5">
          <a-form-item label="部门">
            <a-select showSearch allowClear style="width: 100%" v-model="adjustQuery.department">
              <a-select-option v-for="op in GLOBAL.departmentMaps" :key="op.value">{{ op.name }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col v-bind="GLOBAL.queryColSpan">
          <a-form-item label="申请时间">
            <a-range-picker allowClear @change="onDateChange"/>
          </a-form-item>
        </a-col>
        <a-col v-bind="GLOBAL.queryColSpan">
          <a-form-item label="最近操作时间">
            <a-range-picker allowClear @change="onHandleDateChange"/>
          </a-form-item>
        </a-col>
        <a-col v-bind="GLOBAL.queryColSpan">
          <a-form-item label="业务单号">
            <a-input allowClear v-model="adjustQuery.business_order_num" />
          </a-form-item>
        </a-col>
        <a-col v-bind="GLOBAL.queryColSpan">
          <a-form-item label="调整状态">
            <a-select
              showSearch
              allowClear
              style="width: 500px"
              mode="multiple"
              v-model="adjustQuery.adjust_status_list"
            >
              <a-select-option v-for="op in adjustStatusList" :key="op.code">{{ op.name }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row style="margin:15px 0;">
        <a-button @click="searchInfo" class="ant-btn-query margin-right10">查询</a-button>
      </a-row>
    </a-form-model>
    <a-table
      rowKey="id"
      size="middle"
      :scroll="{ x: true }"
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      @change="changeTable"
      :rowClassName="setRowClass"
    >
      <div slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </div>
      <div slot="orderNumInfo" slot-scope="text, record">
        业务编号:<span style="font-weight: bold">{{record.business_order_num}}</span><br/>
        财商流水:<span style="font-weight: bold">{{record.audit_record_no}}</span><br/>
        财商类型:<a-tag :color="record.fee_type===1?'green':'blue'" style="margin-bottom:2px;">
          {{ record.fee_type===1?'应收':'应付' }}
        </a-tag><br/>
        SAP编号:<span style="font-weight: bold">{{record.fms_sap_num?record.fms_sap_num:'无'}}</span>
      </div>
      <div slot="adjustStatusItem" slot-scope="text">
        <a-tag :color="adjustStatusColor(text)" style="margin-bottom:2px;">
          {{ adjustStatusName(text) }}
        </a-tag>
      </div>
      <div slot="adjustOrdersSlot" slot-scope="text, record" v-if="record.adjust_order_dto">
        <div v-for="item in text.adjust_details" :key="item.id">
          <a-row :gutter="24">
            <a-col :span="4">
              <a-tag :color="adjustOrderTypeColor(item.adjust_type)" style="margin-bottom:2px;">
                {{ item.adjust_type }}
              </a-tag>
            </a-col>
            <a-col :span="20" style="text-align: left">
              <span>{{item.original_invoices}}</span><span v-if="item.new_invoices">-->{{item.new_invoices}}</span>
              <span v-if="item.new_customer_name">{{item.original_customer_name}}-->{{item.new_customer_name}}</span>
              <span v-if="item.new_agency_fee||item.original_agency_fee">{{item.original_agency_fee}}-->{{item.new_agency_fee}}</span>
              <span v-if="item.new_fee_class_name">{{item.original_fee_class_name}}-->{{item.new_fee_class_name}}</span>
              <span v-for="fee in item.delete_fee_dtos" :key="fee">
                {{fee}}<br/>
              </span>
              <span v-for="fee in item.add_fee_dtos" :key="fee">
                {{fee}}<br/>
              </span>
              <span v-for="fee in item.add_exists_fee_dtos" :key="fee">
                {{fee}}<br/>
              </span>
            </a-col>
          </a-row>
        </div>
      </div>
      <div slot="adjustInfoSlot" slot-scope="text, record">
        <span>调整事项：<span style="font-weight: bold">{{record.adjust_type}}</span></span><br/>
        <span>利润是否减少：<a-tag :color="record.effect_profit === '1'?'red':'blue'">{{record.effect_profit === '1' ? '是' : '否'}}</a-tag></span><br/>
        <span>调账说明：<span style="font-weight: bold">{{record.adjust_description}}</span></span>
      </div>
      <div slot="adjustReasonSlot" slot-scope="text, record">
        <span>调整原因：<span style="font-weight: bold">{{record.adjust_reason}}</span></span><br/>
        <span>原因说明：<span style="font-weight: bold">{{record.reason_description}}</span></span>
      </div>
      <div slot="applyUserSlot" slot-scope="text, record">
        <span>{{record.apply_user_name}}</span><br/>
        <span>{{record.apply_time}}</span>
      </div>
      <div slot="lastUserSlot" slot-scope="text, record">
        <span>{{record.last_user_name}}</span><br/>
        <span>{{record.last_handle_time}}</span><br/>
        <span v-if="record.last_handle_remark">操作备注：<span style="font-weight: bold">{{record.last_handle_remark}}</span></span>
      </div>
      <div slot="certRelations" slot-scope="text">
        <span style="display:block" v-for="item in text" :key="item.id">{{ transCertStatus(item.cert_status) }}</span>
      </div>
      <div slot="action" slot-scope="text, record">
        <!-- <a @click="modifyRecord(record)" class="margin-right10">修改</a> -->
        <!-- <a @click="handleCheckAdjust(record)" v-if="record.apply_user_id===currentUserId&&record.adjust_status==='business_apply'&&record.adjust_status==='reject'" class="margin-right10">
          申请
        </a> -->
        <a @click="handleCheckAdjust(record)" v-if="record.next_user_id&&record.next_user_id===currentUserId&&record.adjust_status!=='handle_finish'&&record.adjust_status!=='cancel'" class="margin-right10">
          审核
        </a>
        <a @click="handleCheckAdjust(record)" v-else-if="!record.next_user_id&&currentUserInfo.department === 5 &&(record.adjust_status==='fms_check'||record.adjust_status==='waiting_handle')" class="margin-right10">
          {{record.adjust_status==='waiting_handle'?'执行':'审核'}}
        </a>
        <a @click="handleCheckAdjust(record)" v-if="currentUserId===3&&record.adjust_status!=='handle_finish'&&record.adjust_status!=='cancel'" class="margin-right10">
          审核
        </a>
        <a @click="cancelRecord(record)" v-if="record.apply_user_id===currentUserId&&record.adjust_status!=='handle_finish'" class="margin-right10">取消</a>
        <a v-if="!currentUserInfo.department || currentUserInfo.department === 5" @click="previewCert(record)" class="margin-right10">预览凭证</a>
        <!-- <a @click="generateCert(record)" class="margin-right10">生成凭证</a> -->
        <a @click="queryFlow(record)" class="margin-right10">查看流程</a>
        <a @click="printAdjustBalance(record)" v-if="record.adjust_status==='waiting_handle'||record.adjust_status==='handle_finish'" class="margin-right10">打印调整单</a>
      </div>
      <div slot="bankInfo" slot-scope="text">
        <div v-for="(item, index) in text" :key="index">
          <span style="color: #ffb34f;">{{ item.bank_name }}</span> : <span>{{ item.account }}</span>
        </div>
      </div>
    </a-table>
    <a-modal
      :visible="adjustCheckVisible"
      :confirmLoading="adjustCheckLoading"
      :width="1200"
      :maskClosable="false"
      :title="selectedAdjustInfo.adjust_status!=='business_apply'?'审核调整单':'申请调整单'"
      @cancel="adjustCheckVisible = false">
      <template slot="footer">
        <a-button type="primary" @click="submitApplyCheck">{{selectedAdjustInfo.adjust_status!=='business_apply'?'同意':'申请'}}</a-button>
        <a-button type="danger" v-if="selectedAdjustInfo.adjust_status!=='business_apply'" @click="cancelApplyCheck">驳回</a-button>
        <a-button @click="adjustCheckVisible = false">关闭</a-button>
      </template>
      <a-form-model layout="inline" ref="adjustCheckForm" :model="adjustCheckForm" :rules="adjustCheckRules">
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-model-item label="业务编号">
              <span style="display:block">{{ selectedAdjustInfo.business_order_num }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="利润是否减少">
              <span style="display:block">{{ selectedAdjustInfo.effect_profit === '1'? '是' : '否' }}</span>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-model-item label="调整事项" v-if="selectedAdjustInfo.adjust_order_dto">
              <div v-for="item in selectedAdjustInfo.adjust_order_dto.adjust_details" :key="item.id">
                <a-row :gutter="24">
                  <a-tag :color="adjustOrderTypeColor(item.adjust_type)" style="margin-bottom:2px;">
                    {{ item.adjust_type }}
                  </a-tag>
                  <span>{{item.original_invoices}}</span>
                  <span v-if="item.new_customer_name">{{item.original_customer_name}}-->{{item.new_customer_name}}</span>
                  <span v-if="item.new_fee_class_name">{{item.original_fee_class_name}}-->{{item.new_fee_class_name}}</span>
                  <span v-if="item.new_agency_fee||item.original_agency_fee">{{item.original_agency_fee}}-->{{item.new_agency_fee}}</span>
                  <span v-for="fee in item.delete_fee_dtos" :key="fee">
                    {{fee}}<br/>
                  </span>
                  <span v-for="fee in item.add_fee_dtos" :key="fee">
                    {{fee}}<br/>
                  </span>
                </a-row>
              </div>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-divider orientation="left" style="font-weight:700">更正事项</a-divider>
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-model-item label="调整事项">
              <span style="display:block">{{ selectedAdjustInfo.adjust_type }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :span="16">
            <a-form-model-item label="调账说明">
              <span style="display:block">{{ selectedAdjustInfo.adjust_description }}</span>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-divider orientation="left" style="font-weight:700">更正原因</a-divider>
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-model-item label="更正原因">
              <span style="display:block">{{ selectedAdjustInfo.adjust_reason }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :span="16">
            <a-form-model-item label="更正原因说明" prop="reason_description">
              <span style="display:block">{{ selectedAdjustInfo.reason_description }}</span>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-divider orientation="left" style="font-weight:700"
                  v-if="selectedAdjustInfo.adjust_status !== 'business_apply' && selectedAdjustInfo.adjust_status !== 'handle_finish'">
          审核备注
        </a-divider>
        <a-row :gutter="24" v-if="selectedAdjustInfo.adjust_status !== 'business_apply' && selectedAdjustInfo.adjust_status !== 'handle_finish'">
          <a-col :span="12">
            <a-form-model-item label="审核备注" prop="handle_remark">
              <a-input v-model.trim="adjustCheckForm.handle_remark" style="width: 100%;"/>
            </a-form-model-item>
          </a-col>
          <!-- <a-col :span="12">
            <a-form-model-item v-if="selectedAdjustInfo.adjust_status === 'fms_check'" label="执行人" prop="next_flow_user_id">
              <a-select allowClear style="width: 160px;" v-model="adjustCheckForm.next_flow_user_id">
                <a-select-option v-for="item in fmsUserList" :key="item.id">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col> -->
        </a-row>
      </a-form-model>
    </a-modal>
    <!-- 查询流程 -->
    <a-drawer
      title="查询流程"
      placement="right"
      :width="600"
      :visible="flowVisible"
      :maskClosable="true"
      :destroyOnClose="true"
      @close="flowVisible = false;selectedRowKey = null"
    >
      <a-timeline>
        <a-timeline-item style="padding:0 0 0px" v-for="(item, index) in adjustFlowData" :key="index">
          <a-divider orientation="left" style="font-weight:700"><a-tag :color="adjustStatusColor(item.flow_status)" style="margin-bottom:2px;">{{ item.flow_status_name }}</a-tag></a-divider>
          <a-row :gutter="24">
            <a-col :span="6">
              <span>{{item.flow_user_name}}</span>
            </a-col>
            <a-col :span="8">
              <span>{{item.flow_remark}}</span>
            </a-col>
            <a-col :span="8">
              <span>{{moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')}}</span>
            </a-col>
          </a-row>
          <a-timeline-item color="red" v-if="index === adjustFlowData.length-1 && item.next_flow_status!=='handle_finish'">
            <a-icon slot="dot" type="clock-circle-o" style="font-size: 16px;" />
            <a-divider orientation="left" style="font-weight:700"><a-tag :color="adjustStatusColor(item.next_flow_status)" style="margin-bottom:2px;">{{ item.next_flow_status_name }}</a-tag></a-divider>
            <a-row :gutter="24">
              <a-col :span="8">
                <span>{{item.next_flow_user_name}}</span>
              </a-col>
            </a-row>
          </a-timeline-item>
        </a-timeline-item>
      </a-timeline>
    </a-drawer>
    <a-modal
      :visible="certDetailVisible"
      title="调整凭证信息"
      :width="1500"
      :maskClosable="false"
      @cancel="certDetailVisible = false"
    >
      <empty v-if="certData.length === 0" />
      <a-card title="调整凭证" v-if="certData.length > 0">
        <a-tabs v-model="currentCertId" type="card">
          <a-tab-pane v-for="item in certData" :key="item.id" :tab="item.cert_num + ' (' + transCertStatus(item.cert_status) + ')'">
            <a-table
              rowKey="id"
              :columns="certColumns"
              :data-source="item.cert_info_list"
              :pagination="false"
              :customRow="
                (record, index) => {
                  return {
                    style: {
                      background: item.cert_status === 1 ? '#ffe8e9' : item.cert_status === 2 ? '#f8fadf' : '#e7ffec'
                    }
                  }
                }
              "
            >
            </a-table>
          </a-tab-pane>
        </a-tabs>
      </a-card>
      <template slot="footer">
        <a-button type="danger" @click="delCertInfo">
          删除凭证
        </a-button>
        <a-button type="primary" @click="generateCert">
          重新生成凭证
        </a-button>
        <a-button type="primary" @click="handleConfirm">
          确认凭证
        </a-button>
        <a-button type="primary" @click="handleUnConfirm">
          反确认凭证
        </a-button>
        <a-button type="primary" @click="downloadCert">
          下载凭证
        </a-button>
        <!-- <a-button type="primary" @click="printCert">
          打印凭证
        </a-button> -->
        <a-button @click="certDetailVisible = false">
          关闭
        </a-button>
      </template>
    </a-modal>
    <a-modal
      :visible="printItmsBillModel"
      :confirmLoading="printItmsBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印国贸调整单"
      @cancel="printItmsBillModel = false"
    >
      <div id="printItmsBill">
        <itms-bill
          :currentData="currentData"
          :balanceData="balanceBillData"
        ></itms-bill>
      </div>
      <template slot="footer">
        <a-button key="submit"
                  type="primary"
                  id="printItmsBillBtn"
                  v-print="printItmsBill">确认打印</a-button>
        <a-button type="primary" @click="printItmsBillModel = false">关闭</a-button>
      </template>
    </a-modal>
    <a-modal
      :visible="printSaleBillModel"
      :confirmLoading="printSaleBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印进销存调整单"
      @cancel="printSaleBillModel = false"
    >
      <div id="printSaleAdjustBill">
        <sale-adjust-bill
          :currentData="currentSaleAdjustData"
        ></sale-adjust-bill>
      </div>
      <template slot="footer">
        <a-button key="submit"
                  type="primary"
                  id="printSaleBillBtn"
                  v-print="printSaleAdjustBill">确认打印</a-button>
        <a-button type="primary" @click="printSaleBillModel = false">关闭</a-button>
      </template>
    </a-modal>
    <a-modal
      :visible="printWmsBillModel"
      :confirmLoading="printWmsBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印仓储调整单"
      @cancel="printWmsBillModel = false"
    >
      <div id="printWmsAdjustBill">
        <wms-adjust-bill
          :currentData="currentWmsAdjustData"
        ></wms-adjust-bill>
      </div>
      <template slot="footer">
        <a-button key="submit"
                  type="primary"
                  id="printWmsBillBtn"
                  v-print="printWmsAdjustBill">确认打印</a-button>
        <a-button type="primary" @click="printWmsBillModel = false">关闭</a-button>
      </template>
    </a-modal>
    <a-modal
      :visible="printCumsBillModel"
      :confirmLoading="printCumsBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印客服调整单"
      @cancel="printCumsBillModel = false"
    >
      <div id="printCumsAdjustBill">
        <cums-bill :currentData="currentCumsData" :balanceData="cumsbalanceData"></cums-bill>
      </div>
      <template slot="footer">
        <a-button key="submit"
                  type="primary"
                  id="printCumsBillBtn"
                  v-print="printCumsAdjustBill">确认打印</a-button>
        <a-button type="primary" @click="printCumsBillModel = false">关闭</a-button>
      </template>
    </a-modal>
    <a-modal
      :visible="printYmsBillModel"
      :confirmLoading="printYmsBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印堆场调整单"
      @cancel="printYmsBillModel = false"
    >
      <div id="printYmsAdjustBill">
        <yms-adjust-balance
          :currentData="currentYmsAdjustData"
        ></yms-adjust-balance>
      </div>
      <template slot="footer">
        <a-button key="submit"
                  type="primary"
                  id="printYmsBillBtn"
                  v-print="printYmsAdjustBill">确认打印</a-button>
        <a-button type="primary" @click="printYmsBillModel = false">关闭</a-button>
      </template>
    </a-modal>
    <a-modal
      :visible="printPayBillModel"
      :confirmLoading="printPayBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印付款调整单"
      @cancel="printPayBillModel = false"
    >
      <div id="printPayBill">
        <fms-pay-bill
          :orderList="printPayBillResult"
          :orderData="printPayBillRecord"
          :invoiceList="invoiceList"
          :adjustPayData="adjustPayRecord"
        >
        </fms-pay-bill>
      </div>
      <template slot="footer">
        <a-button id="printPayBillBtn" key="submit" type="primary" v-print="printPayBill">确认打印 </a-button>
        <a-button type="primary" @click="printPayBillModel = false">关闭</a-button>
      </template>
    </a-modal>
    <a-modal
      :visible="printMmsBillModel"
      :confirmLoading="printMmsBillModelLoading"
      :width="1520"
      :maskClosable="false"
      title="打印总经办调整单"
      @cancel="printMmsBillModel = false"
    >
      <div id="printMmsAdjustBill">
        <mms-adjust-bill
          :currentData="currentMmsAdjustData"
        ></mms-adjust-bill>
      </div>
      <template slot="footer">
        <a-button key="submit"
                  type="primary"
                  id="printYmsBillBtn"
                  v-print="printMmsAdjustBill">确认打印</a-button>
        <a-button type="primary" @click="printMmsBillModel = false">关闭</a-button>
      </template>
    </a-modal>
  </a-card>
</template>
<script>
import {
  adjustInit,
  queryPageAdjusts,
  checkAdjustPass,
  cancelAdjust,
  rejectAdjust,
  listFlows,
  generateAdjustCert,
  queryAdjustCertsByAdjustId,
  delCert,
  certConfirm,
  certUnConfirm,
  downloadAdjustCert,
  queryAdjustBalanceInfo,
  querySaleAdjustOrder4Print,
  queryWmsAdjustOrder4Print,
  queryYmsAdjustOrder4Print,
  queryMmsAdjustOrder4Print,
  getBizAuditPage,
  getFmsInfoDetail,
  getInvoiceDataByAuditId
} from '@/api/fms'
import { downloadExcel } from '@/api/common'
import moment from 'moment'
import itmsBill from '@/views/fms/bill/FmsItmsBill';
import cumsBill from '@/views/fms/bill/CumsBill';
import saleAdjustBill from '@/views/fms/bill/SaleAdjustBill';
import wmsAdjustBill from '@/views/fms/bill/WmsAdjustBill';
import ymsAdjustBalance from '@/views/fms/bill/YmsAdjustBalance';
import fmsPayBill from '@/views/fms/bill/FmsPayBill'
import mmsAdjustBill from '@/views/fms/bill/MmsAdjustBill';

export default {
  components: {
    itmsBill, saleAdjustBill, wmsAdjustBill, cumsBill, ymsAdjustBalance, fmsPayBill, mmsAdjustBill
  },
  data() {
    return {
      currentUserId: this.$store.getters.userInfo.id,
      currentUserInfo: this.$store.getters.userInfo,
      labelCol: { span: 5 },
      labelModel: { span: 8 },
      wrapperModel: { span: 12 },
      wrapperCol: { span: 14 },
      formItemLayoutWithOutLabel: {
        wrapperCol: { span: 20, offset: 8 }
      },
      adjustQuery: {
        department: '',
        business_order_num: null,
        apply_begin_date: null,
        apply_end_date: null,
        handle_begin_date: null,
        handle_end_date: null,
        adjust_status: null,
        adjust_status_list: []
      },
      columns: [
        {
          title: '#',
          align: 'center',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '单号',
          dataIndex: 'id'
        },
        {
          title: '部门',
          dataIndex: 'department',
          align: 'center',
          customRender: text => {
            return this.GLOBAL.departmentMaps[text].name
          }
        },
        {
          title: '业务编号',
          dataIndex: 'business_order_num',
          align: 'center',
          scopedSlots: { customRender: 'orderNumInfo' }
        },
        {
          title: '调整事项',
          dataIndex: 'adjust_order_dto',
          align: 'left',
          width: 500,
          scopedSlots: { customRender: 'adjustOrdersSlot' }
        },
        {
          title: '调整内容',
          dataIndex: 'adjust_type',
          align: 'center',
          width: 250,
          scopedSlots: { customRender: 'adjustInfoSlot' }
        },
        {
          title: '调整状态',
          dataIndex: 'adjust_status',
          align: 'center',
          scopedSlots: { customRender: 'adjustStatusItem' }
        },
        {
          title: '调整原因',
          dataIndex: 'adjust_reason',
          align: 'center',
          width: 250,
          scopedSlots: { customRender: 'adjustReasonSlot' }
        },
        {
          title: '申请人',
          dataIndex: 'apply_user_name',
          align: 'center',
          scopedSlots: { customRender: 'applyUserSlot' }
        },
        {
          title: '最近处理人',
          dataIndex: 'last_user_name',
          align: 'center',
          scopedSlots: { customRender: 'lastUserSlot' }
        },
        {
          title: '凭证号',
          dataIndex: 'cert_relation_list',
          align: 'center',
          scopedSlots: { customRender: 'certRelations' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          width: 160,
          scopedSlots: { customRender: 'action' }
        }
      ],
      data: [],
      selectedRowKeys: [],
      visible: false,
      modelTitle: '新增客户供应商信息',
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      moduleName: 'customer_info',
      // 客户供应商部门数据
      departmentList: [
        { value: 1, name: '仓储部' },
        { value: 2, name: '国贸部' },
        { value: 3, name: '总经办' },
        { value: 5, name: '财商部' },
        { value: 6, name: '堆场' },
        { value: 7, name: '客服部' },
        { value: 8, name: '进销存' },
        { value: 9, name: '综合部' }
      ],
      financeTypeList: {
        'charge': { value: 'charge', name: '应收' },
        'pay': { value: 'pay', name: '应付' },
        'all': { value: 'all', name: '应收+应付' }
      },
      adjustStatusList: [],
      fmsUserList: [],
      adjustCheckVisible: false,
      adjustCheckLoading: false,
      selectedAdjustInfo: {},
      adjustCheckForm: {
        adjust_id: null,
        handle_remark: null
      },
      adjustCheckRules: {
        handle_remark: [{ required: true, message: '请填写审核备注', trigger: 'blur' }]
      },
      flowVisible: false,
      adjustFlowData: [],
      selectedRowKey: null,
      certColumns: [
        {
          title: '摘要',
          dataIndex: 'summary',
          width: 300
        },
        {
          title: '业务编号/发票号',
          dataIndex: 'number',
          width: 120
        },
        {
          title: '一级科目',
          dataIndex: 'first_subject',
          width: 200
        },
        {
          title: '明细科目',
          dataIndex: 'detail_subject',
          width: 200
        },
        {
          title: '外币金额',
          dataIndex: 'amount',
          width: 100
        },
        {
          title: '汇率',
          dataIndex: 'exchange_rate',
          width: 100
        },
        {
          title: '金额',
          children: [
            {
              title: '借方',
              dataIndex: 'borrow',
              customRender: text => {
                return text && (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
              },
              align: 'right',
              width: 100
            },
            {
              title: '贷方',
              dataIndex: 'loan',
              customRender: text => {
                return text && (text.toFixed(2) + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
              },
              align: 'right',
              width: 100
            }
          ]
        }
      ],
      currentCertRecord: {},
      certData: [],
      currentCertId: undefined,
      currentCert: {},
      certDetailVisible: false,
      printItmsBillModel: false,
      printItmsBillModelLoading: false,
      printItmsBill: {
        id: 'printItmsBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      currentData: {},
      balanceBillData: [],
      printSaleBillModel: false,
      printSaleBillModelLoading: false,
      printSaleAdjustBill: {
        id: 'printSaleAdjustBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      currentSaleAdjustData: {},
      printWmsBillModel: false,
      printWmsBillModelLoading: false,
      printWmsAdjustBill: {
        id: 'printWmsAdjustBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      currentWmsAdjustData: {},
      printCumsBillModel: false,
      printCumsBillModelLoading: false,
      printCumsAdjustBill: {
        id: 'printCumsAdjustBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      currentCumsData: {},
      cumsbalanceData: {},
      printYmsBillModel: false,
      printYmsBillModelLoading: false,
      printYmsAdjustBill: {
        id: 'printYmsAdjustBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      currentYmsAdjustData: {},
      printMmsBillModel: false,
      printMmsBillModelLoading: false,
      printMmsAdjustBill: {
        id: 'printMmsAdjustBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      currentMmsAdjustData: {},
      printPayBillModel: false,
      printPayBillModelLoading: false,
      printPayBill: {
        id: 'printPayBill',
        popTitle: '',
        extraCss: '',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      printPayBillResult: [],
      printPayBillRecord: {},
      invoiceList: [],
      adjustPayRecord: {}
    }
  },
  created() {
    this.init()
  },
  mounted() {
    this.searchInfo()
  },
  methods: {
    moment,
    init() {
      adjustInit().then(res => {
        this.adjustStatusList = res.ADJUST_STATUS_LIST
        this.fmsUserList = res.FMS_USER_LIST
      })
      if (this.currentUserInfo && this.currentUserInfo.department === 5) {
        this.adjustQuery.adjust_status_list = ['fms_manager_check', 'fms_check', 'waiting_handle', 'handle_finish']
      }
    },
    onDateChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.adjustQuery['apply_begin_date'] = startDate
        this.adjustQuery['apply_end_date'] = endDate
      } else {
        this.adjustQuery['apply_begin_date'] = null
        this.adjustQuery['apply_end_date'] = null
      }
    },
    onHandleDateChange(date, dateString) {
      if (dateString[0]) {
        const startDate = date[0].startOf('day').format('YYYY-MM-DD HH:mm:ss')
        const endDate = date[1].endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.adjustQuery['handle_begin_date'] = startDate
        this.adjustQuery['handle_end_date'] = endDate
      } else {
        this.adjustQuery['handle_begin_date'] = null
        this.adjustQuery['handle_end_date'] = null
      }
    },
    getInfo() {
      queryPageAdjusts(this.adjustQuery).then(res => {
        this.data = res.list
        this.pagination.total = res.total
      })
    },
    searchInfo() {
      console.info(this.$store.getters.userInfo)
      this.adjustQuery['page'] = this.pagination.current
      this.adjustQuery['page_size'] = this.pagination.pageSize
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.getInfo()
    },
    changeTable(pagination) {
      this.pagination.current = pagination.current
      this.pagination.pageSize = pagination.pageSize
      this.adjustQuery['page'] = pagination.current
      this.adjustQuery['page_size'] = pagination.pageSize
      this.getInfo()
    },
    handleCheckAdjust(record) {
      this.selectedAdjustInfo = record
      this.adjustCheckForm.adjust_id = record.id
      this.adjustCheckVisible = true
    },
    submitApplyCheck() {
      this.$refs.adjustCheckForm.validate(valid => {
        if (valid) {
          this.adjustCheckLoading = true
          checkAdjustPass(this.adjustCheckForm).then(res => {
            this.adjustCheckVisible = false
            this.$notification['success']({
              message: '提示',
              description: '操作成功，调整单将进行下一步审核处理'
            })
            this.getInfo()
          }).finally(() => {
            this.adjustCheckLoading = false
          })
        }
      })
    },
    cancelApplyCheck() {
      this.adjustCheckLoading = true
      rejectAdjust(this.adjustCheckForm).then(res => {
        this.adjustCheckVisible = false
        this.$notification['success']({
          message: '提示',
          description: '驳回成功，调整单回到待申请状态'
        })
        this.getInfo()
      }).finally(() => {
        this.adjustCheckLoading = false
      })
    },
    cancelRecord(record) {
      this.$confirm({
        title: '确认提示',
        content: '是否取消该调整单？',
        onOk: () => {
          cancelAdjust({ 'adjust_id': record.id }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '取消成功.'
            })
            this.getInfo()
          })
        }
      })
    },
    queryFlow(record) {
      listFlows({ 'adjust_id': record.id }).then(res => {
        if (res && res.length > 0) {
          this.flowVisible = true
          this.adjustFlowData = res
        } else {
          this.$notification['warn']({
            message: '提示',
            description: '该调整单没有操作记录'
          })
        }
      })
    },
    adjustStatusColor(value) {
      switch (value) {
        case 'business_apply':
          return '#faad14'
        case 'handle_finish':
          return '#4cd964'
        case 'cancel':
          return ''
        case 'reject':
          return ''
        default:
          return '#1890ff'
      }
    },
    adjustStatusName(value) {
      for (let i = 0; i < this.adjustStatusList.length; i++) {
        if (this.adjustStatusList[i].code === value) {
          return this.adjustStatusList[i].name
        }
      }
    },
    setRowClass(record) {
      return record.id === this.selectedRowKey ? 'click-row' : '';
    },
    // 凭证table生成
    previewCert(record) {
      this.currentCertRecord = record
      this.previewAdjustCert(record)
      this.certDetailVisible = true
    },
    // 转账凭证
    previewAdjustCert(record) {
      queryAdjustCertsByAdjustId({
        adjust_id: record.id
      }).then(res => {
        this.certData = res || []
        if (res && res.length > 0) {
          let cert = null
          for (const item of res) {
            if (item.id === this.currentCertId) {
              cert = item
            }
          }
          if (cert) {
            this.currentCertId = cert.id
            this.currentCert = cert
          } else {
            this.currentCertId = res[0].id
            this.currentCert = res[0]
          }
        }
      })
    },
    transCertStatus(status) {
      switch (status) {
        case 1:
          return '待确认'
        case 2:
          return '已确认'
        case 3:
          return '已下载'
        default:
          return ''
      }
    },
    generateCert() {
      generateAdjustCert({ 'adjust_id': this.currentCertRecord.id }).then(res => {
        if (res) {
          this.$message.success('生成成功')
          this.previewAdjustCert(this.currentCertRecord)
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    delCertInfo() {
      delCert({
        id: this.currentCertId
      }).then(res => {
        if (res) {
          this.$message.success('删除凭证成功')
          this.getInfo()
          this.certData = []
          // this.previewChargeCert(this.currentCertRecord)
        }
      })
    },
    handleConfirm() {
      certConfirm({
        id: this.currentCertId
      }).then(res => {
        if (res) {
          this.$message.success('凭证确认成功')
          this.getInfo()
          this.previewAdjustCert(this.currentCertRecord)
        }
      })
    },
    handleUnConfirm() {
      certUnConfirm({
        id: this.currentCertId
      }).then(res => {
        if (res) {
          this.$message.success('凭证反确认成功')
          this.getInfo()
          this.previewAdjustCert(this.currentCertRecord)
        }
      })
    },
    downloadCert(flag) {
      let list = []
      if (flag === 'all') {
        list = this.selectedRowKeys
      } else {
        list.push(this.currentCertRecord.id)
      }
      downloadAdjustCert({
        list,
        type: 1
      }).then(res => {
        if (res !== null) {
          const fileInfo = {
            fileName: res
          }
          downloadExcel(fileInfo).then(downRes => {
            const data = downRes.data
            let fileName = ''
            const contentDisposition = downRes.headers['content-disposition']
            if (contentDisposition) {
              fileName = decodeURI(escape(contentDisposition.split('=')[1]))
            }
            const url = window.URL.createObjectURL(
              new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
            this.getInfo()
            if (flag !== 'all') {
              this.previewAdjustCert(this.currentCertRecord)
            }
          })
        }
      })
    },
    adjustOrderTypeColor(value) {
      switch (value) {
        case '新增费用':
          return 'orange'
        case '删除费用':
          return 'red'
        case '修改客户':
          return 'purple'
        default:
          return 'blue'
      }
    },
    // 结算单
    printAdjustBalance(record) {
      if (record.department === 8) {
        querySaleAdjustOrder4Print({ adjust_id: record.id }).then(res => {
          this.currentSaleAdjustData = res
        }).finally(() => {
          this.printSaleBillModel = true
        })
      } else if (record.department === 1) {
        queryWmsAdjustOrder4Print({ adjust_id: record.id }).then(res => {
          this.currentWmsAdjustData = res
        }).finally(() => {
          this.printWmsBillModel = true
        })
      } else if (record.department === 7) {
        queryAdjustBalanceInfo({ adjust_id: record.id }).then(res => {
          // 统计数据
          let charge = 0
          let pay = 0
          let profit = 0
          res.balance_item_list.forEach(v => {
            v.charge_fees &&
            v.charge_fees.forEach(c => {
              if (c.currency_name === 'CNY') {
                charge += c.amount
              } else {
                charge += c.exchange_amount
              }
            })
            v.pay_fees &&
            v.pay_fees.forEach(c => {
              if (c.currency_name === 'CNY') {
                pay += c.amount
              } else {
                pay += c.exchange_amount
              }
            })
            profit += v.profit
          })
          this.balanceStatic = {
            charge: '应收总计:' + charge.toFixed(2),
            pay: '应付总计:' + pay.toFixed(2),
            profit: '利润总计:' + profit.toFixed(2)
          }
          this.cumsbalanceData = res.balance_item_list
          this.balanceCurrency = res.currency
          this.balanceExchangeRate = res.exchange_rate
          this.currentCumsData.balanceExchangeRate = res.exchange_rate
          this.currentCumsData.balanceStatic = this.balanceStatic
          this.currentCumsData.currency = res.currency
          this.currentCumsData.customer = res.customer_name
          this.currentCumsData.record_no = record.audit_record_no
          this.currentCumsData.applicant_name = record.create_name
          this.currentCumsData.apply_remark = record.remark
          this.currentCumsData.last_add_fees = res.last_add_fees
          this.currentCumsData.adjust_orders = res.cums_adjust_orders
          this.currentCumsData.fms_sap_num = record.fms_sap_num
        }).finally(() => {
          this.printCumsBillModel = true
        })
      } else if (record.department === 6) {
        if (!record.adjust_order_dto.order_fee_type || record.adjust_order_dto.order_fee_type === 1) {
          queryYmsAdjustOrder4Print({ adjust_id: record.id }).then(res => {
            this.currentYmsAdjustData = res
          }).finally(() => {
            this.printYmsBillModel = true
          })
        } else if (record.adjust_order_dto.order_fee_type === 2) {
          this.printPayAdjust(record)
        }
      } else if (record.department === 3) {
        queryMmsAdjustOrder4Print({ adjust_id: record.id }).then(res => {
            this.currentMmsAdjustData = res
            const allfeeInfo = res.mms_adjust_order.balance.fee_infos
            let rentTotalFee = 0; // 租金
            let waterTotalFee = 0; // 水费
            let electricTotalFee = 0; // 电费
            let otherTotalFee = 0; // 其他费用
            allfeeInfo && allfeeInfo.forEach(fee => {
              if (fee.fee_name === '租金') {
                rentTotalFee += fee.charge;
              } else if (fee.fee_name === '水费') {
                waterTotalFee += fee.charge;
              } else if (fee.fee_name === '电费') {
                electricTotalFee += fee.charge;
              } else {
                otherTotalFee += fee.charge;
              }
          });
          this.currentMmsAdjustData.rentTotalFee = rentTotalFee;
          this.currentMmsAdjustData.waterTotalFee = waterTotalFee;
          this.currentMmsAdjustData.electricTotalFee = electricTotalFee;
          this.currentMmsAdjustData.otherTotalFee = otherTotalFee;
          }).finally(() => {
            this.printMmsBillModel = true
          })
      } else {
        if (record.fee_type === 1) {
          queryAdjustBalanceInfo({ adjust_id: record.id }).then(res => {
          // 统计数据
          let charge = 0
          let pay = 0
          let profit = 0
          let originalCharge = 0
          let originalPay = 0
          let originalProfit = 0
          res.balance_item_list.forEach(v => {
            v.charge_fees &&
            v.charge_fees.forEach(c => {
              if (c.currency_name === 'CNY') {
                charge += c.amount
              } else {
                charge += c.exchange_amount
              }
            })
            v.pay_fees &&
            v.pay_fees.forEach(c => {
              if (c.currency_name === 'CNY') {
                pay += c.amount
              } else {
                pay += c.exchange_amount
              }
            })
            profit += v.profit
            v.original_charge_fees &&
            v.original_charge_fees.forEach(c => {
              if (c.currency_name === 'CNY') {
                originalCharge += c.amount
              } else {
                originalCharge += c.exchange_amount
              }
            })
            v.original_pay_fees &&
            v.original_pay_fees.forEach(c => {
              if (c.currency_name === 'CNY') {
                originalPay += c.amount
              } else {
                originalPay += c.exchange_amount
              }
            })
            originalProfit = originalCharge - originalPay
          })
          this.balanceStatic = {
            charge: '应收总计:' + charge.toFixed(2),
            pay: '应付总计:' + pay.toFixed(2),
            profit: '利润总计:' + profit.toFixed(2)
          }
          this.balanceBillData = res.balance_item_list
          this.balanceCurrency = res.currency
          this.balanceExchangeRate = res.exchange_rate
          this.currentData.balanceExchangeRate = res.exchange_rate
          this.currentData.balanceStatic = this.balanceStatic
          this.currentData.currency = res.currency
          this.currentData.customer = res.customer_name
          this.currentData.record_no = record.audit_record_no
          this.currentData.applicant_name = record.create_name
          this.currentData.apply_remark = record.remark
          this.currentData.charge_total = charge;
          this.currentData.pay_total = pay;
          this.currentData.profit = profit;
          this.currentData.original_charge_total = originalCharge;
          this.currentData.original_pay_total = originalPay;
          this.currentData.original_profit = originalProfit;
          this.currentData.reviewed_user_name = record.reviewed_user_name
          this.currentData.adjust_orders = res.adjust_orders
          this.currentData.fms_sap_num = record.fms_sap_num
        }).finally(() => {
          this.printItmsBillModel = true
        })
        } else if (record.fee_type === 2) {
          this.printPayAdjust(record)
        }
      }
    },
    printPayAdjust(record) {
      getBizAuditPage('pay_list', { record_no: record.audit_record_no, department: record.department }).then(res => {
        if (res && res.list.length > 0) {
          getFmsInfoDetail('business_audit', { id: record.audit_record_id }).then(v => {
            const result = [];
            v.forEach(item => {
              const feeList = item.pay_fee_list;
              if (typeof feeList !== 'undefined') {
                feeList.forEach(fee => {
                  result.push({
                    order_num: fee.order_num,
                    quota_num: fee.quota_num,
                    product_name: fee.product_name,
                    fee_name: fee.fee_name,
                    amount: fee.amount
                  });
                });
              }
            });
            this.printPayBillResult = result;
            this.printPayBillRecord = res.list[0];
            this.printPayBillRecord[`currency_name`] = `CNY`;
            this.printPayBillRecord['invoice_count'] = res.list[0].invoice_detail.length
            this.printPayBillModel = true;
          });
          // 根据 id 查询开票信息
          getInvoiceDataByAuditId({ id: record.audit_record_id }).then(v => {
            this.invoiceList = v
          })
          listFlows({ 'adjust_id': record.id }).then(res => {
            if (res && res.length > 0) {
              record.adjust_flow_list = res
            }
          })
          this.adjustPayRecord = record
        } else {
          this.$notification['warn']({
            message: '提示',
            description: '查询不到申请财商记录'
          })
        }
      })
    }
  }
}
</script>
<style scoped>
.ant-table td {
  white-space: nowrap;
}
.margin-right10 {
  margin-right: 10px;
}
.ant-form-item {
  margin-bottom: 4px;
}
::v-deep .click-row {
  background-color: #dff0d8;
}
</style>
