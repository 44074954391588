<template>
  <div id="print">
    <p style="text-align: center; font-weight: 600; font-size: 18px">仓储调整单</p>
    <div style="margin-bottom: 10px">
      <a-row>
        <a-col :span="8" style="display: flex;">
          <div>操作单号：</div>
          <div style="flex: 1;">{{ orderData.business_order_num }}</div>
        </a-col>
        <a-col :span="8" :offset="8" style="text-align: right">
          SAP号：{{ orderData.fms_sap_num }}
        </a-col>
      </a-row>
    </div>
    <div v-for="detail in orderData.wms_adjust_order.adjust_details" :key="detail.id">
      <p style="text-align: left; font-weight: 600">
        <a-divider orientation="left">
          {{ detail.adjust_type }}
        </a-divider>
      </p>
      <table v-if="detail.adjust_type === '删除费用'|| detail.adjust_type === '新增费用'">
        <tbody>
          <tr>
            <th>费用名称</th>
            <th>付款方式</th>
            <th>金额</th>
          </tr>
          <tr v-for="item in detail.delete_fees" :key="item.id">
            <td>{{ item.fee_name }}</td>
            <td>{{ item.charge_type===2?'月结':'现金' }}</td>
            <td>{{ item.amount }}</td>
          </tr>
          <tr v-for="item in detail.add_fees" :key="item.id">
            <td>{{ item.fee_name }}</td>
            <td>{{ item.charge_type===2?'月结':'现金' }}</td>
            <td>{{ item.amount }}</td>
          </tr>
        </tbody>
      </table>
      <table v-if="detail.adjust_type === '修改客户'">
        <tbody>
          <tr>
            <th>原客户</th>
            <th>新客户</th>
          </tr>
          <tr>
            <td class="w100">{{ detail.original_customer_name }}</td>
            <td class="w100">{{ detail.new_customer_name }}</td>
          </tr>
        </tbody>
      </table>
      <table v-if="detail.adjust_type === '重新开票'">
        <tbody>
          <tr>
            <th>原票号</th>
            <th>新票号</th>
          </tr>
          <tr>
            <td class="w100">{{ detail.original_invoices }}</td>
            <td class="w100">{{ detail.new_invoices }}</td>
          </tr>
        </tbody>
      </table>
      <div v-if="detail.adjust_type === '作废发票'">
          <span>作废票号：{{ detail.original_invoices }}</span>
        </div>
    </div>
    <div v-if="orderData.adjust_flow_list">
      <!-- <p style="text-align: left; font-weight: 600">
        <a-divider orientation="left"> 审核信息 </a-divider>
      </p> -->
      <a-row :gutter="24">
        <a-col :span="24">
          调整原因:{{ orderData.reason_description}}
        </a-col>
      </a-row>
      <a-row :gutter="24">
        <a-col :span="4" v-for="flow in orderData.adjust_flow_list" :key="flow.id">
          {{flow.flow_status_name}}：{{flow.flow_user_name}}-{{ moment(flow.created_at).format('YYYY/MM/DD') }}
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: `WmsAdjustBill`,
  components: {},
  props: {
    currentData: { type: Object, default: null }
    // balanceData: { type: Array, default: null }
  },
  created() {
    this.initData();
  },
  watch: {
    currentData(val, oldVal) {
      this.orderData = val
    }
    // balanceData: {
    //   handler(val, oldVal) {
    //     this.initData();
    //   },
    //   deep: true
    // }
  },
  data() {
    return {
      orderData: {},
      orderList: [],
      userInfo: this.$store.getters.userInfo,
      date: moment().year() + '-' + (moment().month() + 1) + '-' + moment().date()
    }
  },
  methods: {
    moment,
    initData() {
      this.orderData = this.currentData
      // this.orderList = this.balanceData
    }
  }
}
</script>

<style scoped>
@page{
  size:  auto;   /* auto is the initial value */
  margin: 3mm;  /* this affects the margin in the printer settings */
}

html{
  background-color: #FFFFFF;
  margin: 0px;  /* this affects the margin on the html before sending to printer */
}

body{
  border: solid 1px blue ;
  width: 80%; margin: 0px auto;
}

@media print {
  html, body {
    height: inherit;
  }
}

.title{
  width: 100%;
}

.gutter-example >>> .ant-row > div {
  background: transparent;
  border: 0;
}

#print { margin: 0 auto; padding: 20px}
#print p, #print table { width: 100% }
table { border-collapse: collapse }
.h100 { height:100px }
td, th { padding: 5px; border: 1px solid #ccc;text-align: center; }
td:nth-child(1), td:nth-child(2) { text-align: center }
td:nth-child(5) { text-align: right }
td:nth-child(6) { text-align: right }
#footer span { display: inline-block; width: 20% }
#footer span:last-child { width: 30% }
</style>
