<template>
  <div id="print">
    <p style="text-align: center; font-weight: 600">调整单</p>
    <div style="margin-bottom: 10px">
      <a-row>
        <a-col :span="8" style="display: flex;">
          <div>发票抬头：</div>
          <div style="flex: 1;">{{ orderData.audit_record.customer_name }}</div>
        </a-col>
        <a-col :span="8" :offset="8" style="text-align: right">
          业务编号：
          <span>{{ orderData.business_order_num }}</span>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="8">
        </a-col>
        <a-col :span="8" :offset="8" style="text-align: right">
          申请日期：{{ orderData.audit_record.apply_time }}
        </a-col>
      </a-row>
    </div>
    <div>
      <table>
        <thead>
          <tr>
            <th>费用名称</th>
            <th>金额(不含税)</th>
            <th>税率</th>
            <th>价税合计</th>
            <th>增/普票</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in orderData.yms_balance_notice_sheet_dtos" :key="index">
            <td>{{ item.fee_name }}</td>
            <td>{{ item.price_total }}</td>
            <td>{{ item.tax_rate }}</td>
            <td>{{ item.tax_price_total }}</td>
            <td v-if="orderData.yms_adjust_order.yms_balance.invoice_type === 0">普通发票</td>
            <td v-else-if="orderData.yms_adjust_order.yms_balance.invoice_type === 1">专用发票</td>
            <td v-else-if="orderData.yms_adjust_order.yms_balance.invoice_type === 2">缴款单</td>
            <td v-else>-</td>
          </tr>
          <tr class="h100">
            <td style="font-weight: bold;">备注</td>
            <td colspan="5" style="font-weight: bold;">{{ orderData.audit_record.apply_remark }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <p id="footer">
      <span>执行人：{{ userInfo.name }}</span>
      <span>申请人：{{ orderData.audit_record.applicant_name }}</span>
      <span>部门经理：张慧</span>
      <span>申请时间：{{ date }}</span>
    </p>
    <div>
      <p style="text-align: center; font-weight: 800;">
        <a-divider orientation="center" style="font-size: 18px">
          {{ orderData.yms_adjust_order.serial_num }} 调整单{{ orderData.fms_sap_num ? '（SAP编号:' + orderData.fms_sap_num + '）' : '' }}
        </a-divider>
      </p>
      <div v-for="detail in orderData.yms_adjust_order.adjust_details" :key="detail.id">
        <p style="text-align: left; font-weight: 600">
          <a-divider orientation="left">
            {{ detail.adjust_type }}
          </a-divider>
        </p>
        <table v-if="detail.adjust_type === '删除费用'|| detail.adjust_type === '新增费用'">
          <tbody>
            <tr>
              <th>费用名称</th>
              <th>金额(不含税)</th>
              <th>税率</th>
              <th>价税合计</th>
            </tr>
            <tr v-for="fee in detail.delete_fees" :key="fee.id">
              <td>{{ fee.fee_name }}</td>
              <td>{{ fee.de_tax }}</td>
              <td>{{ fee.tax_rate/100 }}</td>
              <td>{{ fee.money }}</td>
            </tr>
            <tr v-for="fee in detail.add_fees" :key="fee.id">
              <td>{{ fee.fee_name }}</td>
              <td>{{ fee.de_tax }}</td>
              <td>{{ fee.tax_rate/100 }}</td>
              <td>{{ fee.money }}</td>
            </tr>
          </tbody>
        </table>
        <div v-if="detail.adjust_type === '修改客户'">
          <span>由 <span style="font-weight: bold">{{ detail.original_customer_name}}</span> 改为 <span style="font-weight: bold">{{ detail.new_customer_name}}</span></span>
        </div>
        <div v-if="detail.adjust_type === '重新开票'">
          <span>由 <span style="font-weight: bold">{{ detail.original_invoices}}</span> 红冲重开 <span style="font-weight: bold">{{ detail.new_invoices}}</span></span>
        </div>
        <div v-if="detail.adjust_type === '作废发票'">
          <span>作废票号：{{ detail.original_invoices }}</span>
        </div>
        <div v-if="detail.adjust_type === '修改费用种类'">
          <span>由 <span style="font-weight: bold">{{ detail.original_fee_class_name}}</span> 改为 <span style="font-weight: bold">{{ detail.new_fee_class_name}}</span></span>
        </div>
      </div>
      <div v-if="orderData.adjust_flow_list">
        <p style="text-align: left; font-weight: 600">
          <a-divider orientation="left"> 审核信息 </a-divider>
        </p>
        <a-row :gutter="24">
          <a-col :span="24">
            调整原因:{{ orderData.reason_description}}
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="6" v-for="flow in orderData.adjust_flow_list	" :key="flow.id">
            {{flow.flow_status_name}}：{{flow.flow_user_name}}-{{ moment(flow.created_at).format('YYYY/MM/DD') }}
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'YmsAdjustBalance',
  props: {
    currentData: { type: Object, default: null }
  },
  watch: {
    currentData(val, oldVal) {
      this.orderData = val
    }
  },
  data() {
    return {
      orderData: {},
      userInfo: this.$store.getters.userInfo,
      date: moment().year() + '-' + (moment().month() + 1) + '-' + moment().date()
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    moment,
    initData() {
      this.orderData = this.currentData
      console.info(this.orderData)
    }
  }
}
</script>

<style scoped>
@page{
  size:  auto;   /* auto is the initial value */
  margin: 3mm;  /* this affects the margin in the printer settings */
}

html{
  background-color: #FFFFFF;
  margin: 0px;  /* this affects the margin on the html before sending to printer */
}

body{
  border: solid 1px blue ;
  width: 80%; margin: 0px auto;
}

@media print {
  html, body {
    height: inherit;
  }
}

.title{
  width: 100%;
}

.gutter-example >>> .ant-row > div {
  background: transparent;
  border: 0;
}

#print { width: 80%; margin: 0 auto; padding-top: 50px }
#print p, #print table { width: 100% }
table { border-collapse: collapse }
.h100 { height:100px }
td, th { padding: 5px; border: 1px solid #ccc;text-align: center; }
#footer span { display: inline-block; width: 20% }
#footer span:last-child { width: 30% }
</style>
